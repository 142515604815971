<script setup>

</script>
<template>
  <div class="container">
  <h1 class="UiKitSkeleton_root UiKitSkeleton_verticalWave UiKitSkeleton_light" style="width: 100%; height: 30px;">&nbsp;</h1>
  </div>
  <div class="MenuListSkeleton_items">
    <div class="UiKitSkeleton_root UiKitSkeleton_verticalWave UiKitSkeleton_light" style="height: 280px; border-radius: 24px;">&nbsp;</div>
    <div class="UiKitSkeleton_root UiKitSkeleton_verticalWave UiKitSkeleton_light" style="height: 280px; border-radius: 24px;">&nbsp;</div>
    <div class="UiKitSkeleton_root UiKitSkeleton_verticalWave UiKitSkeleton_light" style="height: 280px; border-radius: 24px;">&nbsp;</div>
    <div class="UiKitSkeleton_root UiKitSkeleton_verticalWave UiKitSkeleton_light" style="height: 280px; border-radius: 24px;">&nbsp;</div>
    <div class="UiKitSkeleton_root UiKitSkeleton_verticalWave UiKitSkeleton_light" style="height: 280px; border-radius: 24px;">&nbsp;</div>
    <div class="UiKitSkeleton_root UiKitSkeleton_verticalWave UiKitSkeleton_light" style="height: 280px; border-radius: 24px;">&nbsp;</div>
    <div class="UiKitSkeleton_root UiKitSkeleton_verticalWave UiKitSkeleton_light" style="height: 280px; border-radius: 24px;">&nbsp;</div>
    <div class="UiKitSkeleton_root UiKitSkeleton_verticalWave UiKitSkeleton_light" style="height: 280px; border-radius: 24px;">&nbsp;</div>
  </div>
</template>
<script>
export default {
  name: 'Loader',
}
</script>
<style scoped>
.MobileUILoader_spinnerContainerVisible_globalLoader.MobileUILoader_spinnerContainer_globalLoader {
  opacity: 1;
}
.MobileUILoader_spinnerContainer_globalLoader {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  opacity: 0;
  z-index: 2;
  position: absolute;
}

.MenuListSkeleton_root {
  display: flex;
  padding: 16px;
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  padding-top: 11px;
  flex-direction: column;
}
.MenuListSkeleton_withoutCatCarousel {
  padding-top: 6px;
}
.MenuListSkeleton_withoutMenu {
  padding-bottom: 13px;
}
.MenuListSkeleton_carousel {
  width: 100%;
  display: flex;
  margin-top: 0;
}
.MenuListSkeleton_carousel:not(:last-child) {
  margin-bottom: 19px;
}
.MenuListSkeleton_carousel > * {
  flex-grow: 1;
  flex-shrink: 0;
  margin-right: 16px;
}
.MenuListSkeleton_category {
  margin: 27px 0 20px;
}
.MenuListSkeleton_items {
  width: 100%;
  display: flex;
  padding: 0;
  flex-wrap: wrap;
}
.MenuListSkeleton_items > * {
  width: calc(50% - 4px);
  flex-grow: 1;
  flex-shrink: 0;
  margin-right: 8px;
  margin-bottom: 8px;
}
.MenuListSkeleton_items > *:nth-child(2n) {
  margin-right: 0;
}

.UIKitSpinner_root_globalLoader {
  display: inline-block;
  animation: UIKitSpinner_keyframes-rotateAnimation_globalLoader 1500ms infinite;
  background-size: 100%;
  animation-direction: alternate;
  background-position: center;
  animation-timing-function: cubic-bezier(0.42,0,0.58,1);
}
@-moz-keyframes UIKitSpinner_keyframes-rotateAnimation_globalLoader {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(720deg);
  }
}

@-moz-keyframes UiKitSkeleton_keyframes-verticalWave {
  0% {
    background-position: 150%;
  }
  100% {
    background-position: -50%;
  }
}
@-moz-keyframes UiKitSkeleton_keyframes-angledWave {
  0% {
    background-position: 200%;
  }
  100% {
    background-position: -100%;
  }
}
@-moz-keyframes UiKitSkeleton_keyframes-lightFade {
  0% {
    background-color: #302F2D;
  }
  40% {
    background-color: #FCFBF9;
  }
  100% {
    background-color: #302F2D;
  }
}
@-moz-keyframes UiKitSkeleton_keyframes-darkFade {
  0% {
    background-color: #E7E6E4;
  }
  40% {
    background-color: #F1F0ED;
  }
  100% {
    background-color: #E7E6E4;
  }
}

@keyframes UIKitSpinner_keyframes-rotateAnimation_globalLoader {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(720deg);
  }
}

@keyframes UiKitSkeleton_keyframes-verticalWave {
  0% {
    background-position: 150%;
  }
  100% {
    background-position: -50%;
  }
}
@keyframes UiKitSkeleton_keyframes-angledWave {
  0% {
    background-position: 200%;
  }
  100% {
    background-position: -100%;
  }
}
@keyframes UiKitSkeleton_keyframes-lightFade {
  0% {
    background-color: #302F2D;
  }
  40% {
    background-color: #FCFBF9;
  }
  100% {
    background-color: #302F2D;
  }
}
@keyframes UiKitSkeleton_keyframes-darkFade {
  0% {
    background-color: #E7E6E4;
  }
  40% {
    background-color: #F1F0ED;
  }
  100% {
    background-color: #E7E6E4;
  }
}


@-webkit-keyframes UIKitSpinner_keyframes-rotateAnimation_globalLoader {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(720deg);
  }
}

@-webkit-keyframes UiKitSkeleton_keyframes-verticalWave {
  0% {
    background-position: 150%;
  }
  100% {
    background-position: -50%;
  }
}
@-webkit-keyframes UiKitSkeleton_keyframes-angledWave {
  0% {
    background-position: 200%;
  }
  100% {
    background-position: -100%;
  }
}
@-webkit-keyframes UiKitSkeleton_keyframes-lightFade {
  0% {
    background-color: #302F2D;
  }
  40% {
    background-color: #FCFBF9;
  }
  100% {
    background-color: #302F2D;
  }
}
@-webkit-keyframes UiKitSkeleton_keyframes-darkFade {
  0% {
    background-color: #E7E6E4;
  }
  40% {
    background-color: #F1F0ED;
  }
  100% {
    background-color: #E7E6E4;
  }
}

.UiKitSkeleton_root {
  overflow: hidden;
  position: relative;
  line-height: inherit;
}
.UiKitSkeleton_light {
  background-color: #302F2D;
}
.UiKitSkeleton_dark {
  background-color: #E7E6E4;
}
.UiKitSkeleton_verticalWave {
  animation: UiKitSkeleton_keyframes-verticalWave 1s linear infinite;
  background-size: 200%;
  background-image: linear-gradient(90deg, rgba(33, 32, 31, 0) 0%, rgba(33, 32, 31, 0.05) 33%, rgba(33, 32, 31, 1) 50%, rgba(33, 32, 31, 0.05) 66%, rgba(33, 32, 31, 0) 100%);
}
.UiKitSkeleton_verticalWave.UiKitSkeleton_dark {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.05) 33%, rgba(255, 255, 255, 0.75) 50%, rgba(255, 255, 255, 0.05) 66%, rgba(255, 255, 255, 0) 100%);
}
.UiKitSkeleton_angledWave {
  animation: UiKitSkeleton_keyframes-angledWave 2s linear infinite;
  background-size: 300%;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.05) 40%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0.05) 60%, rgba(255, 255, 255, 0) 100%);
}
.UiKitSkeleton_angledWave.UiKitSkeleton_dark {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.05) 40%, rgba(255, 255, 255, 0.75) 50%, rgba(255, 255, 255, 0.05) 60%, rgba(255, 255, 255, 0) 100%);
}
.UiKitSkeleton_fade.UiKitSkeleton_light {
  animation: UiKitSkeleton_keyframes-lightFade 1s infinite;
}
.UiKitSkeleton_fade.UiKitSkeleton_dark {
  animation: UiKitSkeleton_keyframes-darkFade 1s infinite;
}
</style>