import BottomOffcanvas from "@/components/UI/BottomOffcanvas";
import HeaderComponent from "@/components/UI/HeaderComponent";
import Drawer from "@/components/UI/Drawer";
import Switcher from "@/components/UI/Switcher";
import NavDrawer from "@/components/UI/NavDrawer";
import BottomBar from "@/components/UI/BottomBar";
import Informer from "@/components/UI/Informer.vue";
import Loader from "@/components/UI/Loader.vue";


export default [
	BottomOffcanvas,
	HeaderComponent,
	Drawer,
	NavDrawer,
	BottomBar,
	Switcher,
	Informer,
	Loader,
]