<script setup>
import router from "@/router/router";
import {useCartStore} from "@/store/cart";
const cartStore = useCartStore();
cartStore.clearCart();
let tg = window.Telegram.WebApp;
tg.BackButton.hide();
tg.MainButton.text = "Открыть меню";
tg.MainButton.isVisible = true;
tg.MainButton.color = "#b61918";
tg.MainButton.textColor = "#fff";
tg.MainButton.onClick(function() {
  tg.HapticFeedback.impactOccurred('medium');
  router.push('/');
});

</script>

<template>
  <div class="container">
    <div class="col-md-12">
      <h2>Служба доставки «Два Поэта»</h2><br>
      <p>Телефон: <a style="color:white;font-weight: bold;" href="tel:+73812308508">+7 (3812) 308 - 508</a></p>
      <p>Заказы принимаются ежедневно с 08:00 до 20:00. Обращаем Ваше внимание, что заказы на текущий день принимаются до 19:30. Заказы на утро, принимаются накануне вечером, до 20:00</p>
      <p>Заказы доставляются с 9:00 до 21:00</p>
      <h2>Адреса и контакты Гастробуфетов «Два Поэта»</h2><br>
      <p>Режим работы с 8:00 до 22:00</p>
      <div class="accordion">
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
              «Два Поэта» на Пушкина 112
            </button>
          </h2>
          <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <iframe src="https://yandex.ru/map-widget/v1/?z=12&ol=biz&oid=45769487646" width="100%" height="400" frameborder="0"></iframe>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              «Два Поэта» на 70 Лет Октября 7
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <iframe src="https://yandex.ru/map-widget/v1/?z=12&ol=biz&oid=4670083023" width="100%" height="400" frameborder="0"></iframe>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              «Два Поэта» на Красный Путь 63
            </button>
          </h2>
          <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <iframe src="https://yandex.ru/map-widget/v1/?z=12&ol=biz&oid=162013641823" width="100%" height="400" frameborder="0"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12" style="min-height: 400px;"></div>
  </div>
</template>

<style scoped>
.accordion-button.collapsed {
  background: #302F2D;
  color: white;
  font-weight: bold;
}
.accordion-button {
  background: #42413E !important;
  color: white;
  font-weight: bold;
}
.accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
  color: white;
}
.accordion-button:not(.collapsed) {
  color: white;
  background-color: transparent;
  box-shadow: none;
}
.accordion-button.collapsed::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.accordion-body {
  padding: 0;
}

</style>