<template>
	<div class="AppCategoriesLayout row row-cols-2">
		<div class="col PlaceListBduItem_placesListItem" v-for="category in categories">
			<div class="CategoryItemLayout" @click="$router.push(`/category/${category.slug}`)">
				<div class="CategoryItemImageWrapper">
					<img class="CategoryItemImage" :src="category.thumbnail" :alt="category.title">
				</div>
				<div class="CategoryMetaLayout">
					<h3 data-testid="mobile-place-item-name" class="MobilePlaceItem_titleBig" :title="category.title">{{ category.title }}</h3>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			categories: {
				type: Array,
				required: true,
			}
		}
	}

</script>

<style></style>