<script setup>
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { defineProps } from 'vue'
import { useCategoriesStore } from '@/store/categories'
import { useTelegramStore } from "@/store/telegram";
import {useCartStore} from "@/store/cart";
import Informer from "@/components/UI/Informer.vue";
import Loader from "@/components/UI/Loader.vue";

const useCatStore = useCategoriesStore();
const tgStore = useTelegramStore();
const cartStore = useCartStore();
let tg = window.Telegram.WebApp;
var a = tg.initDataUnsafe.user;
tg.expand();
tg.BackButton.hide();
tgStore.setStore(a);
useCatStore.fetchAll();
const categ = computed(() => useCatStore.list);
if(!cartStore.initLoad) {
  cartStore.clearCart();
  cartStore.initLoad = true;
}

</script>

<template>
	<h1 class="main-heading">Меню доставки</h1>
  <!--<informer id="inform" v-bind:text="'Акция! При заказе от 1500 руб. в подарок Салат-боул Цезарь мини.'"/>-->
  <br>
  <loader v-if="!useCatStore.loaded"></loader>
	<categories-list v-if="useCatStore.loaded" :categories="categ"></categories-list>
</template>

<style scoped>
[data-testid="informer"] .UiKitCommonInformer_description {
  max-height: 46px !important;
}
[data-testid="informer"] .UiKitCommonInformer_text[data-v-2980f02a] {
  padding: 12px 0px !important;
  flex-grow: 2;
  text-align: left;
}
[data-testid="informer"] .MobileRestaurantComponentsCategory_informers {
  padding: 0px 16px 0 !important;
}
[data-testid="informer"] .UiKitText_withLineClamp {
  display: -webkit-box;
  overflow: initial !important;
  word-break: normal !important;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

</style>

<script>
	import CategoriesList from "@/components/CategoriesList";
	import axios from 'axios'
	export default {
		components: {
			CategoriesList
		},
	}
</script>