import { defineStore } from 'pinia'
const fakeStoreUrl = 'https://двапоэта.рф/api'


export const useCategoriesStore = defineStore({
  id: 'categories',

  state: () => ({
    items: {},
    ids: [],
    category: null,
  }),

  getters: {
    list(state) {
      return this.ids.map(i => this.items[i])
    },
    loaded(state) {
      return this.ids.length > 0
    },
  },

  actions: {
    async fetchAll() {
      if (this.loaded) return;
      const res = await fetch(`${fakeStoreUrl}/allfood`)
      const data = await res.json()
      this.ids = data.map((product) => {
        this.items[product.id] = product
        return product.id
      })
    },


  },
});
