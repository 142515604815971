<template>
  <div class="MobileRestaurantComponentsCategory_informers">
    <div class="UiKitCommonInformer_root UiKitCommonInformer_background UiKitCommonInformer_background-d7 MobilePlaceInformer_root UiKitCommonInformer_mobile UiKitCommonInformer_s" data-testid="informer">
      <div class="UiKitCommonInformer_mainContent">
        <div class="UiKitCommonInformer_iconContainer">

          <div class="MobilePlaceInformer_icon"><img :src="require('/public/images/saleIcon.png')"  aria-hidden="true"></div>
        </div>
        <div class="UiKitCommonInformer_text">
          <div class="UiKitCommonInformer_description UiKitCommonInformer_description-d6 UiKitText_root UiKitText_Caption1Tight UiKitText_Regular UiKitText_Text UiKitText_withLineClamp" style="-webkit-line-clamp: 2; max-height: 28px;">{{text}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'informer',
  props: ['text']
}
</script>

<style scoped>
#inform .UiKitCommonInformer_description {
  max-height: 46px !important;
}
#inform .UiKitCommonInformer_text[data-v-2980f02a] {
  padding: 12px 0px !important;
  flex-grow: 2;
  text-align: left;
}
#inform .MobileRestaurantComponentsCategory_informers {
  padding: 0px 16px 0 !important;
}
#inform .UiKitText_withLineClamp {
  display: -webkit-box;
  overflow: initial !important;
  word-break: normal !important;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.MobilePlaceInformer_root + .MobilePlaceInformer_root {
  margin-top: 8px;
}
.UiKitCommonInformer_s.UiKitCommonInformer_background {
  background-size: auto 56px;
  border-radius: 16px;
}

.UiKitCommonInformer_background-d7 {
  background-color: rgb(18, 18, 17);
}
.UiKitCommonInformer_mainContent {
  width: 100%;
  display: flex;
  align-items: center;
}
.UiKitCommonInformer_s .UiKitCommonInformer_image, .UiKitCommonInformer_s .UiKitCommonInformer_iconContainer {
  width: 56px;
  height: 56px;

}
.UiKitCommonInformer_iconContainer {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.MobilePlaceInformer_icon {
  width: 56px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.MobilePlaceInformer_icon > img {
  max-width: 100%;
  max-height: 100%;
}
.UiKitCommonInformer_text {
  padding: 8px 0px;
  flex-grow: 2;
  text-align: left;
}
.UiKitCommonInformer_mobile .UiKitCommonInformer_description {
  margin-top: 1px;
}
.UiKitCommonInformer_description-d6 {
  color: rgb(245, 244, 242);
}
.UiKitCommonInformer_description {
  display: -webkit-box;
  overflow: hidden;
  margin-top: 2px;
  -moz-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.MobileRestaurantComponentsCategory_informers {
  padding: 0px 16px 0;
}
.UiKitText_withLineClamp {
  display: -webkit-box;
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
</style>